<template>
  <div class="flex justify-center">
    <ul>
      index
    </ul>
  </div>
</template>

<script>
export default {
  layout: 'blog',
  computed: {
    articles() {
      return this.$store.state.articles;
    },
  },
};
</script>
